$headerHight: 3rem;
// COLORS
$darkClr: #333333;
$darkClr-light: #8a8a8a8a;
$lightClr: #f2f2f2;
$whiteClr: #ffffff;
$softGreenClr: #B3FFAB;
$bodyBcg: #FFEFBA;

// SHADOWS
$shadowOne: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

// BORDER RADIUS
$radius: .5rem;

// ROTATE IMAGE
$rotateImg: rotate(-30deg);
// FONTS
$fontXlg: 4rem;
$fontLg: 3rem;
$fontSlg: 1.5rem;
$fontMd: 1rem; //normal-font-size: 1rem;
$fontSm: .875rem; //small-font-size

// MARGINS
$mb-1: .5rem;
$mb-2: 1rem;
$mb-3: 1.5rem;
$mb-4: 2rem;
$mb-5: 2.5rem;
$mb-6: 3rem;

// 