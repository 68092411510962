@use "./mixins";
@use "./variables" as *;

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
html{
    font-size: 100%;
    scroll-behavior: smooth;
    width: 100%; 
    min-height: 100%; 
}
body {
    width: inherit; 
    min-height: inherit; 
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    // background: $bodyClr;
    overflow-x: hidden;
}
img {
    max-width: 100%;
    height: auto;
    display: block;
}
ul{
    list-style: none;
}
li {
    list-style-type: none;
}
a {
    text-decoration: none;
}
h1 {
    font-size: 3rem;
    @include mixins.breakpoint-up(medium){
        font-size: 3.5rem;
    }
}
h2 {
    font-size: 2.5rem;
    @include mixins.breakpoint-up(medium){
        font-size: 2.8rem;
    }
}
h3 {
    font-size: 1.7rem;
    @include mixins.breakpoint-up(medium){
        font-size: 2rem;
    }
}
button{
    cursor: pointer;
    border: none;
    outline: none;
}

// REUSABLES
.section {
    padding: 5rem 2rem;

    @include mixins.breakpoint-up(medium){
        padding: 7rem 0;
    }

    &-title {
        text-transform: capitalize;
        position: relative;
        margin-bottom: $mb-4;
        text-align: center;
        letter-spacing: .1rem;
        background: linear-gradient(170deg, #fcc923 27.16%, #2b02fc 97.86%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        
        &::after {
            content: "";
            position: absolute;
            width: 3.5rem;
            height: .18rem;
            top: -1rem;
            left: 0;
            right: 0;
            margin: auto;
            background-color: $darkClr;

            @include mixins.breakpoint-up(medium) {
                width: 4.75rem;
            }
        }
    }
}

.grid {
    max-width: 64rem; // 1024px
    width: calc(100% - 2rem);
    margin: 0 auto;
    display: grid;
    grid-template-columns: 100%;
    column-gap: 2rem;
}